import React from 'react';
import Select from 'react-select';
import AdlForm from './integration_forms/Azure/adl';
import ADFForm from './integration_forms/Azure/adf';
import KafkaForm from './integration_forms/StreamingConnectors/kafka';
import DODSForm from './integration_forms/SAP/dods';
import AzureForm from './integration_forms/Azure/azure';
import AdlGen2Form from './integration_forms/Azure/adl_gen2';
import DatabricksTableForm from './integration_forms/Azure/AzuredatabricksTable';
import DatabricksSqlForm from './integration_forms/Azure/AzuredatabricksSql';
import S3Form from  './integration_forms/AWS/s3';
import SigmaForm from  './integration_forms/BIConnectors/sigma';
import TableauForm from  './integration_forms/BIConnectors/tableau';
import DBTForm from  './integration_forms/TransformationConnectors/dbt';
import Redshift from  './integration_forms/AWS/AWS_redshift';
import AthenaForm from './integration_forms/AWS/athena';
import BigQueryForm  from  './integration_forms/GCS/big_query';
import SnowflakeIntegration from './integration_forms/Snowflake/snowflake_integration_form';
import ClickhouseIntegration from './integration_forms/Clickhouse/clickhouse_integration';
import PostgresForm from './integration_forms/postgres/postgres';
import MySqlForm from './integration_forms/MYSQL/mysql_form';
import AzureSynapseDSForm from './integration_forms/Azure/azure_synapse_ds';
import TestConnection from './testConnection';
import {helpContent,get_document_link, getSupportedDatasourceOptions} from "../../utils/common_utils";
import AzureSQL  from  './integration_forms/Azure/azureSQL';
import PowerBi  from  './integration_forms/PowerBi/PowerBi';
import GcpSQL from './integration_forms/GCS/gcp_sql';
import GcsForm from './integration_forms/GCS/gcs';
import VerticaForm from './integration_forms/Vertica/vertica';
import {getIntegrationDetail, getQualdoIps} from "../../utils/event_handling"
import Load from '../../components/loadAction';
import $ from 'jquery';
import {POWER_BI_CONNECTOR, QUALDO_DOCUMENTATION,
    AZURE_SYNAPSE_DS_CONNECTOR} from "../../utils/constant";
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);


class Integration extends React.Component {
    constructor(props) {
        super(props);
        this.setForm = this.setForm.bind(this);
        this.closeTestConnection = this.closeTestConnection.bind(this);
        this.setIntegrationData = this.setIntegrationData.bind(this);
        this.setSubmitButton = this.setSubmitButton.bind(this);
        this.setData = this.setData.bind(this);
        this.fill_details= this.fill_details.bind(this);
        this.changeHelpContent = this.changeHelpContent.bind(this);
        this.getIntegrationDetail = getIntegrationDetail.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.getQualdoIps = getQualdoIps.bind(this);

        const supportedDatasourceOptions = getSupportedDatasourceOptions();

        let formType = "";
        if (supportedDatasourceOptions.length > 0) {
            formType = supportedDatasourceOptions[0].options[0].value;
        }

        this.state = {
            submit_button:false,
            testConnection:null,
            data_source_options: supportedDatasourceOptions,
            formType:  formType,
            formType_option:{"label":"Azure Blob", "value":'azure_blob'},
            helpContentKey: formType,
            integration_data:{
                integration_type:formType,
                data_store_type: "filesystem"
            },
            ex_edit_integration_details: null,
            ip_address: null
        };
    }

    componentDidMount() {
        this.getQualdoIps();
        this.fill_details();
    }

    fill_details() {
        // Removed defaultValues as we given default value directly inside the state variable
        if (this.props.ex_integration_details !== undefined) {
            let values = this.FillFormDetails(this.props.ex_integration_details);
            //eslint-disable-next-line
            this.state.formType = values[1];
            //eslint-disable-next-line
            this.state.formType_option = values[0];
        }
    }

    setForm = (selectedOption) => {
        this.setState ({
            formType: selectedOption["value"],
            helpContentKey: selectedOption["value"]
        });
        this.closeTestConnection();
    }

    closeTestConnection() {
        this.setState ({
            testConnection: null
        });
    }

    setIntegrationData(data) {
        this.setState ({
            integration_data: data
        });
    }

    setData(integration_data,testConnection_data) {
       this.setState({testConnection:testConnection_data,
                      integration_data:integration_data,
       });
    }

    setSubmitButton(value) {
        this.setState({submit_button:value});
    }


    renderForm(formType) {
        if (formType === "azure_blob") {
            return (<AzureForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        } else if(formType === "azure_data_factory") {
            return (<ADFForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        } else if(formType === "kafka") {
                        return (<KafkaForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        } else if(formType === "dods") {
                        return (<DODSForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        } else if (formType === "azure_data_lake_gen2") {
            return (<AdlGen2Form
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType="azure_data_lake_gen2"
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        } else if(formType === "azure_data_lake") {
            return (<AdlForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        } else if(formType === "azure_databricks_table") {
            return (<DatabricksTableForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        }
        else if(formType === "azure_synapse_ds") {
            return (<AzureSynapseDSForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);

        } else if(formType === "azure_databricks_sql") {
            return (<DatabricksSqlForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);}
        else if(formType === "s3") {
            return(<S3Form
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        }
        else if(formType === "redshift") {
            return(<Redshift
                         integration_data={this.state.integration_data}
                         ex_integration_details={this.state.ex_edit_integration_details}
                         setData={this.setData}
                         showCancel={this.props.showCancel}
                         showToast={this.props.showToast}
                         setPage={this.props.setPage}
                         closeModal={this.props.closeModal}
                         closeTestConnection={this.closeTestConnection}
                         setSubmitButton={this.setSubmitButton}
                         setEnvData={this.props.setEnvData}
                         env_id={this.props.env_id}
                         autocomplete = "new-password"
                         />);
        }
        else if(formType === "athena") {
            return(<AthenaForm
                         integration_data={this.state.integration_data}
                         ex_integration_details={this.state.ex_edit_integration_details}
                         setData={this.setData}
                         showCancel={this.props.showCancel}
                         showToast={this.props.showToast}
                         setPage={this.props.setPage}
                         closeModal={this.props.closeModal}
                         closeTestConnection={this.closeTestConnection}
                         setSubmitButton={this.setSubmitButton}
                         setEnvData={this.props.setEnvData}
                         env_id={this.props.env_id}
                         autocomplete = "new-password"
                         />);
        }
        else if(formType === "big_query") {
            return(<BigQueryForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}/>);
        }else if(formType === "azure_sql") {
           return(<AzureSQL
                              integration_data={this.state.integration_data}
                              ex_integration_details={this.state.ex_edit_integration_details}
                              setData={this.setData}
                              showCancel={this.props.showCancel}
                              showToast={this.props.showToast}
                              setPage={this.props.setPage}
                              closeModal={this.props.closeModal}
                              closeTestConnection={this.closeTestConnection}
                              setSubmitButton={this.setSubmitButton}
                              setEnvData={this.props.setEnvData}
                              env_id={this.props.env_id}
                              autocomplete = "new-password"
                              />);
        }else if(formType === "gcp_sql") {
           return(<GcpSQL
                              integration_data={this.state.integration_data}
                              ex_integration_details={this.state.ex_edit_integration_details}
                              setData={this.setData}
                              showCancel={this.props.showCancel}
                              showToast={this.props.showToast}
                              setPage={this.props.setPage}
                              closeModal={this.props.closeModal}
                              closeTestConnection={this.closeTestConnection}
                              setSubmitButton={this.setSubmitButton}
                              setEnvData={this.props.setEnvData}
                              env_id={this.props.env_id}
                              autocomplete = "new-password"
                              />);
        }
        else if(formType === "snowflake") {
             return(<SnowflakeIntegration
                              integration_data={this.state.integration_data}
                              ex_integration_details={this.state.ex_edit_integration_details}
                              setData={this.setData}
                              showCancel={this.props.showCancel}
                              showToast={this.props.showToast}
                              setPage={this.props.setPage}
                              closeModal={this.props.closeModal}
                              closeTestConnection={this.closeTestConnection}
                              setSubmitButton={this.setSubmitButton}
                              setEnvData={this.props.setEnvData}
                              env_id={this.props.env_id}
                              autocomplete = "new-password"
                              />);

        } else if(formType === "postgresql") {
             return(<PostgresForm
                              integration_data={this.state.integration_data}
                              ex_integration_details={this.state.ex_edit_integration_details}
                              setData={this.setData}
                              showCancel={this.props.showCancel}
                              showToast={this.props.showToast}
                              setPage={this.props.setPage}
                              closeModal={this.props.closeModal}
                              closeTestConnection={this.closeTestConnection}
                              setSubmitButton={this.setSubmitButton}
                              setEnvData={this.props.setEnvData}
                              env_id={this.props.env_id}
                              autocomplete = "new-password"
                              />);

        }else if(formType === "vertica") {
             return(<VerticaForm
                              integration_data={this.state.integration_data}
                              ex_integration_details={this.state.ex_edit_integration_details}
                              setData={this.setData}
                              showCancel={this.props.showCancel}
                              showToast={this.props.showToast}
                              setPage={this.props.setPage}
                              closeModal={this.props.closeModal}
                              closeTestConnection={this.closeTestConnection}
                              setSubmitButton={this.setSubmitButton}
                              setEnvData={this.props.setEnvData}
                              env_id={this.props.env_id}
                              autocomplete = "new-password"
                              />);

        }
        else if(formType === "mysql") {
              return(<MySqlForm
                        integration_data={this.state.integration_data}
                              ex_integration_details={this.state.ex_edit_integration_details}
                              setData={this.setData}
                              showCancel={this.props.showCancel}
                              showToast={this.props.showToast}
                              setPage={this.props.setPage}
                              closeModal={this.props.closeModal}
                              closeTestConnection={this.closeTestConnection}
                              setSubmitButton={this.setSubmitButton}
                              setEnvData={this.props.setEnvData}
                              env_id={this.props.env_id}
                              autocomplete = "new-password"
                              />)

        }else if(formType === "gcs") {
           return(<GcsForm
                              integration_data={this.state.integration_data}
                              ex_integration_details={this.state.ex_edit_integration_details}
                              setData={this.setData}
                              showCancel={this.props.showCancel}
                              showToast={this.props.showToast}
                              setPage={this.props.setPage}
                              closeModal={this.props.closeModal}
                              closeTestConnection={this.closeTestConnection}
                              setSubmitButton={this.setSubmitButton}
                              setEnvData={this.props.setEnvData}
                              env_id={this.props.env_id}
                              autocomplete = "new-password"
                              />);
        }else if(formType === POWER_BI_CONNECTOR) {
           return(<PowerBi
                              integration_data={this.state.integration_data}
                              ex_integration_details={this.state.ex_edit_integration_details}
                              setData={this.setData}
                              showCancel={this.props.showCancel}
                              showToast={this.props.showToast}
                              setPage={this.props.setPage}
                              closeModal={this.props.closeModal}
                              closeTestConnection={this.closeTestConnection}
                              setSubmitButton={this.setSubmitButton}
                              setEnvData={this.props.setEnvData}
                              env_id={this.props.env_id}
                              autocomplete = "new-password"
                              />);
        }else if(formType === "sigma" || formType === "looker") {
            return(<SigmaForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        }else if(formType === "tableau") {
            return(<TableauForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        }else if(formType === "dbt") {
            return(<DBTForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id}
                        autocomplete = "new-password"
                        />);
        }else if(formType === "clickhouse") {
             return(<ClickhouseIntegration
                              integration_data={this.state.integration_data}
                              ex_integration_details={this.state.ex_edit_integration_details}
                              setData={this.setData}
                              showCancel={this.props.showCancel}
                              showToast={this.props.showToast}
                              setPage={this.props.setPage}
                              closeModal={this.props.closeModal}
                              closeTestConnection={this.closeTestConnection}
                              setSubmitButton={this.setSubmitButton}
                              setEnvData={this.props.setEnvData}
                              env_id={this.props.env_id}
                              autocomplete = "new-password"
                              />);

        }

    }

    renderTest() {
        if (this.state.testConnection != null) {
            return <TestConnection integration_data={this.state.integration_data}
                                   testConnection_data={this.state.testConnection}
                                   submit_button={this.state.submit_button}
                                   closeTestConnection={this.closeTestConnection}
                                   src_page={this.props.src_page}
                                   setPage={this.props.setPage}
                                   showToast={this.props.showToast}
                                   closeModal={this.props.closeModal}
                                   existingIntegrations={this.props.existingIntegrations}
                                   setEnvData={this.props.setEnvData}
                                   env_id={this.props.env_id}
                                   ip_address={this.state.ip_address}
                />;
        }
    }


    FillFormDetails(integration_details) {
        let defaultValue, formType = null;
        const integration_type = integration_details["integration_type"];
        if (integration_type === undefined){
            return [null, null];
        }
        if(integration_type === "azure_blob") {
            formType = "azure_blob";
            defaultValue = {"label":"Azure Blob", "value":'azure_blob'};
        } else if (integration_type === "azure_data_lake_gen2") {
             formType = "azure_data_lake_gen2";
            defaultValue = {"label":'Azure Data Lake Gen2', "value":'azure_data_lake_gen2'};
        } else if (integration_type === "azure_data_factory") {
            formType = "azure_data_factory";
            defaultValue = {"label":'Azure Data Factory', "value":'azure_data_factory'};
        } else if (integration_type === "kafka") {
            formType = "kafka";
            defaultValue = {"label":'Kafka', "value":'kafka'};
        } else if (integration_type === "dods") {
            formType = "dods";
            defaultValue = {"label":'SAP DODS', "value":'dods'};
        } else if (integration_type === "azure_data_lake") {
             formType = "azure_data_lake";
            defaultValue = {"label":'Azure Data Lake', "value":'azure_data_lake'};
        } else if (integration_type === "azure_databricks_table") {
            formType = "azure_databricks_table";
            defaultValue = {"label":'Azure Databricks Table', "value":'azure_databricks_table'};
        } else if (integration_type === "azure_databricks_sql") {
            formType = "azure_databricks_sql";
            defaultValue = {"label":'Azure Databricks SQL', "value":'azure_databricks_sql'};
        } else if (integration_type.includes("snow_flake")) {
             formType = "snowflake";
             defaultValue = {"label":'Snowflake', "value":'snowflake'};
        } else if (integration_type.includes("clickhouse")) {
             formType = "clickhouse";
             defaultValue = {"label":'ClickHouse', "value":'clickhouse'};
        }  else if (integration_type.includes("s3")) {
             formType = "s3";
             defaultValue = {"label":'AWS S3', "value":'s3'};
        } else if (integration_type.includes("azure_sql")) {
             formType = "azure_sql";
             defaultValue = {"label":"Azure SQLServer", "value":'azure_sql'};
        }else if (integration_type.includes("gcp_sql")) {
             formType = "gcp_sql";
             defaultValue = {"label":"GCP SQLServer", "value":'gcp_sql'};
        }else if (integration_type.includes("big_query")) {
             formType = "big_query";
             defaultValue = {"label":"Google BigQuery", "value":'big_query'};
        } else if (integration_type.includes("postgresql")) {
             formType = "postgresql";
             defaultValue = {"label":"Postgres", "value":'postgresql'};
        } else if (integration_type.includes("mysql")) {
             formType = "mysql";
             defaultValue = {"label":"MySQL", "value":'mysql'};
        }else if (integration_type.includes("gcs")) {
            formType = "gcs";
            defaultValue = {"label":"GCS", "value":'gcs'};
        }  else if (integration_type.includes("redshift")) {
            formType = "redshift";
            defaultValue = {"label":'AWS redshift', "value":'redshift'};
        } else if (integration_type.includes("athena")) {
            formType = "athena";
            defaultValue = {"label":'Athena', "value":'AWS Athena'};
        }
        else if (integration_type.includes(POWER_BI_CONNECTOR)) {
            formType = POWER_BI_CONNECTOR;
            defaultValue = {"label":'Power Bi', "value":POWER_BI_CONNECTOR};
        }
        else if (integration_type === AZURE_SYNAPSE_DS_CONNECTOR) {
            formType = AZURE_SYNAPSE_DS_CONNECTOR;
            defaultValue = {"label":'Azure Synapse', "value":AZURE_SYNAPSE_DS_CONNECTOR};
        }
        else {
             formType = "AWS";
             defaultValue = {"label":'AWS', "value":'AWS'};
        }
        return [defaultValue, formType];
    }

    changeHelpContent(key) {
        this.setState({helpContentKey: key});
    }

    renderHelpContent() {
        let content = [];
        let info = [];
        if(this.state.formType === "AWS" ) {
            info = helpContent["aws"];
        } else if (this.state.formType === "snowflake") {
            info = helpContent["snowflake"];
        } else if (this.state.formType === "clickhouse") {
            info = helpContent["clickhouse"];
        } else if(this.state.helpContentKey === "azure_blob") {
            info = helpContent["azure_blob"];
        } else if(this.state.helpContentKey === "azure_data_lake_gen2") {
            info = helpContent["azure_blob"];
        } else if(this.state.helpContentKey === "azure_data_lake") {
            info = helpContent["azure_data_lake"];
        } else if(this.state.helpContentKey === "azure_databricks_table") {
            info = helpContent["azure_databricks_table"];
        } else if(this.state.helpContentKey === "azure_databricks_sql") {
            info = helpContent["azure_databricks_sql"];
        } else if(this.state.formType === "s3" ) {
            info = helpContent["aws"];
        } else if(this.state.formType === "redshift" ) {
            info = helpContent["redshift"];
        } else if(this.state.formType === "athena" ) {
            info = helpContent["athena"];
        } else if(this.state.formType === "azure_sql" ) {
            info = helpContent["azure_sql"];
        }else if(this.state.formType === "gcp_sql" ) {
            info = helpContent["gcp_sql"];
        }else if(this.state.formType === "big_query" ) {
            info = helpContent["big_query"];
        } else if (this.state.formType === "postgresql"){
            info = helpContent["postgresql"];
        }else if (this.state.formType === "mysql"){
            info = helpContent["mysql"];
        }
        else if (this.state.formType === "gcs"){
            info = helpContent["gcs"];
        }
        else if (this.state.formType === "azure_synapse_ds"){
            info = helpContent["azure_synapse_ds"];
        }
        else if (this.state.formType === "vertica"){
            info = helpContent["vertica"];
        }
        else {
            info = helpContent["azure_blob"];
        }
        let k=0;
        for (k=0; k < info.length; k++) {
            if(k=== (info.length-1) && this.state.formType !== 'big_query'){
                content.push(<li>Make sure Qualdo's IP : <strong> {this.state.ip_address} </strong> is added to your authorised network(s).</li>);
                content.push(<li>{info[k]} Refer to the <a href={get_document_link("data_source")} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a> for more details.</li>);
                }
                else if(k=== (info.length-1) && this.state.formType === 'big_query'){
                    content.push(<li>{info[k]} Refer to the <a href={get_document_link("data_source")} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a> for more details.</li>);
                    content.push(<li>Make sure Qualdo's IP : <strong> {this.state.ip_address} </strong> is added to your authorised network(s).</li>);

                }
                else {
                    if(this.state.formType === 'azure_databricks_table' || this.state.formType === 'azure_databricks_sql'){
                       if((k >= 3 && k <= 6) || (k >= 8 && k <= 11)){
                           content.push(<ol start="1">{info[k]}</ol>);
                       }
                       else{
                        content.push(<li>{info[k]}</li>);
                    }
                    }
                    else{
                        content.push(<li>{info[k]}</li>);
                    }
                   }
        }   

        if($( window ).width() > 768){
            let addheight = $('#add-datasource').height();
            $('#add-datasource_help').height(addheight - 26);
        }    
        return content;
    }

    getAddIntegrationContent(data_source_option){
        return (
            <>
                <div className = "portlet portlet-2col" id="add_data_source_panel">
                    <div className="portlet-title">
                        <div className="caption">
                            <span className="text-uppercase font-dark">{this.props.src_page === "setup" ? "Add your first DataSource" : "Add DataSource"}</span>
                        </div>
                        <div className="actions"></div>
                    </div>
                    <div className = "portlet-body" >
                        <div className="row">
                            <div className={this.props.ex_integration_details !== undefined ? "col-md-12" : "col-md-8"}>
                                <div className="portlet-body"id="add-datasource">
                                    <div className="form-row">
                                        <div className="form-group col-md-6 hello">
                                            <label>Choose a Datasource type </label>
                                            <Select
                                                id="choose_datasource_type_add"
                                                filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                onChange={this.setForm}
                                                defaultValue={this.state.formType_option}
                                                options={data_source_option}
                                                placeholder="Choose Data Source"
                                                classNamePrefix='select-control'
                                                formatGroupLabel={formatGroupLabel}
                                            />
                                        </div>
                                    </div>
                                    { this.renderForm(this.state.formType) }
                                </div>
                            </div>
                                <div className="col-md-4">
                                    <div className="portlet border-0 mb-0">
                                        <div className="portlet-title">
                                            <div className="caption">
                                                <span className="text-uppercase">Help</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portlet-body p-0">
                                        {/* <h4 id="tooltipupdate">
                                            <div>
                                                <code className="highlighter-rouge">Steps to enable access from your storage to Qualdo™: </code>
                                                <a className="anchorjs-link " href="#tooltipupdate"
                                                    aria-label="Anchor" data-anchorjs-icon="#" style={{paddingLeft: "0.375em"}}>
                                                </a>
                                            </div>
                                        </h4> */}
                                        {/* <figure className="highlight" style={{"height": "250px","overflow-y": "auto"}}>
                                            {this.renderHelpContent()}
                                        </figure> */}

                                        <div id="add-datasource_help" class="qd-helpGuide">
                                        <div class="qd-helpGuide__content">
                                             <h4 class="qd-helpGuide__title">Steps to enable access from your storage to Qualdo™: </h4>
                                             <ol class="qd-helpGuide__info">
                                             {this.renderHelpContent()}
                                             </ol>
                                        </div>
                                        </div>


                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                { this.renderTest() }
             </>
        );
    }

    getEditIntegrationContent(data_source_option){
        return (
            <div>
                <div className = "portlet-body" id="edit_ds_model">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="portlet-body">
                                <div className="portlet-body">
                                        <div className="form-row">
                                            <div className="form-group col-md-6 hello">
                                                <label>Choose a Datasource type</label>
                                                <Select
                                                    id="choose_datasource_type_edit"
                                                    filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                    onChange={this.setForm}
                                                    defaultValue={this.state.formType_option}
                                                    options={data_source_option}
                                                    placeholder="Choose Data Source"
                                                    formatGroupLabel={formatGroupLabel}
                                                    classNamePrefix='select-control'
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                        { this.renderForm(this.state.formType) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.renderTest() }
            </div>
        );
    }

    render() {
        const data_source_option = this.state.data_source_options;
        let editLoad = false;
        if (this.props.src_page === "edit_integration" && this.state.ex_edit_integration_details === null) {
            editLoad = true;
            this.getIntegrationDetail(this.props.ex_integration_details["integration_id"], "azure_blob");
        }

        return (
            editLoad ?
                <Load/>
                :
                <>
                    {
                        this.props.ex_integration_details !== undefined ?
                            this.getEditIntegrationContent(data_source_option) :
                            this.getAddIntegrationContent(data_source_option)
                    }
                </>
        );
    }
}
export default Integration;